import "./App.css";
import Layout from "./layouts";
import HomePage from "./pages/home";
import { TonConnectButton, TonConnectUIProvider, THEME} from '@tonconnect/ui-react';

export default function App() {
  return (
    <Layout>
     
        <div className="app">
        <HomePage />
        </div>

    </Layout>
  );
}
